<!-- 项目配置弹窗 -->
<template>
  <div>
    <el-dialog
      width="60%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'拍照模板':'拍照模板'"
      @update:visible="updateVisible">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px">
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="模板名称：" prop="template_name">
              <el-input
                placeholder="请输入内容"
                v-model="form.template_name"
                clearable>
              </el-input>
            </el-form-item>
            <!--<el-form-item label="服务项目" prop="items">-->
            <!--  <el-select-->
            <!--    clearable-->
            <!--    v-model="form.items"-->
            <!--    placeholder="请选择"-->
            <!--    class="ele-fluid">-->
            <!--    <el-option v-for="(item) in ServicesAvailable" :label="item.label" :value="item.serial_number"/>-->
            <!--  </el-select>-->
            <!--</el-form-item>-->
          </el-col>
          <el-col :span="12">
            <el-form-item label="客户名称" label-width="100px" prop="channel_id">
              <el-select
                clearable
                v-model="form.channel_id"
                placeholder="请选择"
                class="ele-fluid">
                <el-option v-for="(item) in client" :label="item.channel_name" :value="parseInt(item.id)"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="15">
          <el-col :span="12">
            <div style="display: flex;align-items: center;margin-top: 11px;">
              <span style="width: 5px;height: 16px;background: #ff9b05;display:inline-block; margin-right: 10px;"></span>
              <span style="font-size: 18px;font-weight: 700;color: #3f4155;">已添加的示例图</span>
            </div>
            <div style="margin-top: 20px;padding: 20px;background: #f7f8fa;border: 1px solid #e9eaf1;border-radius: 6px;">
              <el-row style="background: #E9ECF1;text-align: center;">
                <el-col :span="4" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                  编号
                </el-col>
                <el-col :span="8" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                  名称
                </el-col>
                <el-col :span="6" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                  示例图
                </el-col>
                <el-col :span="6" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                  操作
                </el-col>
              </el-row>

              <draggable v-model="dataList" group="people" @change="log">
                <template>
                  <el-row style="background: #FFFFFF;text-align: center; cursor: pointer;" v-for="(item,index) in dataList" :key="index">
                    <el-col :span="4" style=" border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;justify-content: center;">
                      <span>{{item.id}}</span>
                    </el-col>
                    <el-col :span="8" style="border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;">
                      <span style="margin-left: 8px;margin-right: 8px;">{{item.sample_graph_name}}</span>
                    </el-col>
                    <el-col :span="6" style="border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;justify-content: center;">
                      <img :src="item.sample_graph_path" alt="" style="width: 50px;height: 45px;">
                    </el-col>
                    <el-col :span="6" style="border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;justify-content: center;">
                      <span style="margin-right: 15px;">拖动排序</span>
                      <el-popconfirm title="确定删除吗？" @confirm="del(item,index)">
                        <span slot="reference">删除</span>
                      </el-popconfirm>
                    </el-col>
                  </el-row>
                </template>
              </draggable>
              <el-empty v-if="dataList.length == 0" description="数据为空"></el-empty>
            </div>
          </el-col>

          <el-col :span="12">
            <el-row :gutter="15" style="display: flex;align-items: center;">
              <el-col :span="6">
                <div style="display: flex;align-items: center;">
                  <span style="width: 5px;height: 16px;background: #ff9b05;display:inline-block; margin-right: 10px;"></span>
                  <span style="font-size: 18px;font-weight: 700;color: #3f4155;">选择示例图</span>
                </div>
              </el-col>
              <el-col :span="10" style="display: flex;">
                <el-input
                  placeholder="请输入内容"
                  v-model="form.input"
                  clearable>
                </el-input>
                <el-button type="primary" icon="el-icon-search">搜索</el-button>
              </el-col>
              <el-col :span="8">
                <el-button style="background: #31334B!important;border-color: #31334B!important;color: #FFFFFF!important;" @click="addimg()">
                  <i class="el-icon-tianjia" />
                  <span style="vertical-align: middle">添加示例图</span>
                </el-button>
              </el-col>
            </el-row>

            <div>
              <div style="margin-top: 20px;padding: 20px;background: #f7f8fa;border: 1px solid #e9eaf1;border-radius: 6px;">
                <el-row style="background: #E9ECF1;text-align: center;">
                  <el-col :span="4" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                    编号
                  </el-col>
                  <el-col :span="8" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                    名称
                  </el-col>
                  <el-col :span="6" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                    示例图
                  </el-col>
                  <el-col :span="6" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                    操作
                  </el-col>
                </el-row>

                <el-row style="background: #FFFFFF;text-align: center; cursor: pointer;" v-for="(item,index) in List" :key="index">
                  <el-col :span="4" style=" border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;justify-content: center;">
                    <span>{{item.id}}</span>
                  </el-col>
                  <el-col :span="8" style="border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;">
                    <span style="margin-left: 8px;margin-right: 8px;">{{item.sample_graph_name}}</span>
                  </el-col>
                  <el-col :span="6" style="border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;justify-content: center;">
                    <img :src="item.sample_graph_path" alt="" style="width: 50px;height: 45px;">
                  </el-col>
                  <el-col :span="6" style="border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;justify-content: center;">
                    <el-popconfirm title="确定添加吗？" @confirm="Addtemplate(item,index)">
                      <span slot="reference" style="color: #FF9B05;">添加到模板</span>
                    </el-popconfirm>
                  </el-col>
                </el-row>

                <div v-if="empty == false">
                  <el-pagination
                      style="text-align: center; margin-top: 20px"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="total"
                      :page-size="where.limit"
                      :current-page="where.page"
                      @size-change="ChangeSize"
                      @current-change="currentChange"
                      background>
                  </el-pagination>
                </div>

                <div v-if="empty == true">
                  <el-empty description="数据为空"></el-empty>
                </div>

              </div>

            </div>

          </el-col>
        </el-row>
      </el-form>

      <div slot="footer">
        <el-button
          @click="updateVisible(false)">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save('form')">确定
        </el-button>
      </div>
    </el-dialog>

    <!--添加示例图-->
    <el-dialog
      width="30%"
      center
      :visible="addSampleImage"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'添加示例图':'添加示例图'"
      @update:visible="addSampleImageVisible">

      <el-form
        ref="form1"
        :model="form1"
        :rules="rules1"
        label-width="100px">
        <el-row :gutter="15">
          <el-col :span="24">
            <el-form-item label="示例图名称" prop="sample_graph_name">
              <el-input
                placeholder="请输入示例图名称"
                v-model="form1.sample_graph_name"
                clearable>
              </el-input>
            </el-form-item>
            <el-form-item label="拍照要求:" prop="claim">
              <el-input
                placeholder="请输入拍照要求"
                v-model="form1.claim"
                clearable>
              </el-input>
            </el-form-item>
            <el-form-item label="拍照图例">
              <div class="upload_bg_shouchi">
                <el-upload
                  :action="action"
                  :headers="headers"
                  :on-success="successUpload"
                  :limit="1"
                  accept=".jpg, .png, jpeg"
                  :file-list="fileList"
                  :class="{hide_box: handupload_btn}"
                  :on-change="handchange"
                  list-type="picture-card"
                  :on-preview="handPreview"
                  :on-remove="handhandleRemove">
                  <!--<i class="el-icon-plus"></i>-->
                  <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
                  <div style="font-size: 12px;font-weight: 500;color: #ff9b05;margin-top: 10px;">上传拍照示例</div>
                  <div style="font-size: 12px;font-weight: 400;color: #c1c2ce;margin-top: 20px;">支持jpg，jpeg，png格式，最大10M</div>
                </el-upload>
                <el-dialog :visible.sync="handdialogVisible">
                  <img width="100%" :src="handdialogImageUrl" alt="">
                </el-dialog>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button
          @click="addSampleImageVisible()">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="addSampleImageVisiblesave('form1')">确定
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'
//拖动排序
import draggable from 'vuedraggable'
import {
  Addphoto_templates,
  Addsamples,
  Detalisphoto_templates,
  Editphoto_templates,
  getsamples,
} from "@/api/operate";
//服务项目接口
import {getget_services_available} from '@/api/yunli'
// 客户列表接口
import {get_select} from "@/api/custom";
export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {
    draggable
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({items:'',channel_id:'',sample:[]}),
      rules: {
        template_name: [
          {required: true, message: '请输入模板名称', trigger: 'blur'},
          { min: 0, max: 20, message: '长度最多输入20个字符', trigger: 'blur' }
        ],
        items: [
          {required: true, message: '请选择服务项目', trigger: 'change'},
        ],
        channel_id:[
          {required: true, message: '请选择客户', trigger: 'change'},
        ]
      },

      // 验证规则
      form1:{},
      rules1: {
        sample_graph_name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          { min: 0, max: 20, message: '长度最多输入20个字符', trigger: 'blur' }
        ],
        claim: [
          { required: true, message: '请输入拍照要求', trigger: 'blur' },
          { min: 0, max: 80, message: '长度最多输入80个字符', trigger: 'blur' }
        ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //拍照模板弹窗
      photoTemplate:false,
      // 已添加的示例图
      dataList:[],

      // 添加示例图弹窗
      addSampleImage:false,
      //营业执照照片
      handdialogImageUrl:'',
      handdialogVisible:false,
      handupload_btn:false,
      fileList:[],

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/capacity',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

      //选择示例图
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 总条目数
      total: 0,
      //已添加的示例图数组
      List:[],

      //服务项目
      ServicesAvailable:[],
      //客户列表
      client:[],

      empty:false,

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        // this.form = Object.assign({}, this.data);
        this.isUpdate = true;
        Detalisphoto_templates(this.data.id).then(res => {
          console.log(res);
          this.form.items = res.data.items;
          this.form.channel_id = res.data.channel_id;
          this.form.sample = res.data.new_sample;
          this.dataList = res.data.new_sample;
        })
      } else {
        this.form = {
          sample: []
        };
        this.form.sample = [];
        this.isUpdate = false;
      }
    }
  },
  mounted() {
    // 获取选择示例图列表
    this.getList();
    //获取服务项目
    this.getFuwuxiangmu();
    // 获取客户
    this.getKehu();
  },
  methods: {
    //获取服务项目
    getFuwuxiangmu(){
      getget_services_available().then(res => {
        this.ServicesAvailable = res.data;
      })
    },
    //获取客户名称列表
    getKehu(){
      get_select().then(res => {
        this.client = res.data;
      })
    },

    // 选择示例图事件
    // 获取选择示例图列表
    getList(){
      getsamples(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        if(this.List.length == 0){
          this.empty = true;
        }else {
          this.empty = false;
        }
      }).catch((res) => {
        this.$Message.error(res.msg);
      })
    },
    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },
    //点击添加到模板
    Addtemplate(item,index){
      if(this.dataList.length == 0){
        this.dataList.push(item);
        console.log('获取dataList',this.dataList)
      }else {
        this.dataList.forEach(res => {
          if(item.id == res.id){
            console.log(item)
            // this.$Message.error('该示例图已添加到模板');
            this.$message({
              message: '该示例图已添加到模板',
              type: 'error',
              customClass:'messageIndex'
            });
          }else {
            console.log('添加')
            this.dataList.push(item);
          }
        })
      }

      // 数组去重
      const map = new Map()
      const qc = this.dataList.filter(key => !map.has(key.id) && map.set(key.id, 1)) // 这里对id属性进行去重
      // console.log('qc')
      // console.log(qc)
      this.dataList = qc;

      // this.dataList.push(item);
      // console.log(this.dataList);
    },



    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        console.log(this.form);
        if (valid) {
          this.loading = true;
          //判断是新增还是修改
          if(this.isUpdate === false){
            this.dataList.forEach(item => {
              this.form.sample.push(item.id);
            })
            Addphoto_templates(this.form).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }else {
            this.form.sample = [];
            this.dataList.forEach(item => {
              this.form.sample.push(item.id);
            })
            Editphoto_templates(this.data.id,this.form).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }
        } else {
          // 聚焦功能
          this.$firstInputFocus();
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //点击添加照片模板
    add(){
      //关闭项目配置弹窗
      this.updateVisible(false);
      //显示添加照片模板弹窗
      this.photoTemplate = true;
    },
    //关闭添加照片模板弹窗
    photoTemplateVisible(){
      this.photoTemplate = false;
      this.updateVisible(true);
    },
    // 确定添加
    addSampleImageVisiblesave(){
      console.log('添加')
      console.log(this.form1);
      this.$refs['form1'].validate((valid) => {
        if (valid) {
          this.loading = true;
          Addsamples(this.form1).then(res => {
            console.log(res)
            this.loading = false;
            if (res.code === 200) {
              this.$message.success(res.msg);
              if (!this.isUpdate) {
                this.form1 = {};
              }
              this.updateVisible(true);
              this.addSampleImage = false;
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.msg);
          })
        } else {
          return false;
        }
      });
    },

    // 拖动排序事件
    log: function(evt) {
      window.console.log(evt);
    },
    // 删除
    del(item,index){
      console.log(item);
      // this.$utils.confirm("确定要删除吗？", function () {
      //   // 执行一些需要的逻辑
      //   console.log('点击确定')
      // }, "确定", 'warning');
      console.log('点击删除');
      //删除数组元素
      this.dataList.splice(index,1);
    },

    // 点击添加示例图
    addimg(){
      //显示添加示例图
      this.addSampleImage = true;
      //隐藏添加照片模板
      this.photoTemplate = false;
      //隐藏项目配置弹窗
      this.updateVisible(false);

      // 营业执照 图片相关
      this.handdialogImageUrl = '';
      this.handdialogImageUrl = false;
      this.handupload_btn = false;
      this.fileList = [];

    },
    // 关闭添加示例图弹窗
    addSampleImageVisible(){
      this.addSampleImage = false;
      this.updateVisible(true);
    },

    //营业执照照片事件
    handchange(){
      this.handupload_btn = true;
    },
    handhandleRemove(){
      this.handupload_btn = false;
    },
    handPreview(file){
      this.handdialogImageUrl = file.url;
      this.handdialogVisible = true;
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file);
      console.log(fileList)
      this.handdialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form1.sample_graph_path = file.data.urls;
    },


  }
}
</script>

<style lang="scss" scoped>
  .hide_box /deep/ .el-upload--picture-card {
    display: none;
  }
</style>
